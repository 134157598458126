<!-- Start Main Container Area -->
<div class="main-container main-container-sticky">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/home-twenty-eight">
                <img src="assets/img/logo.png" alt="image">
            </a>
        </div>

        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav navbar-style-two">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#shop">Shop</a></li>
                    <li class="nav-item"><a class="nav-link" href="#brand">Brand</a></li>
                    <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>
                    <li class="nav-item"><a class="nav-link" href="#instagram">Instagram</a></li>
                </ul>
            </div>
        </div>

        <div class="aside-footer">
            <a href="#" class="default-btn">Shop Now</a>

            <ul class="social">
                <li><a href="#" target="_blank"><i class="ri-facebook-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-twitter-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-linkedin-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-dribbble-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
            </ul>
        </div>

        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">

        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="home-area">
            <div class="home-slides owl-carousel owl-theme">
                <div class="main-banner banner-bg6"></div>
                <div class="main-banner banner-bg7"></div>
                <div class="main-banner banner-bg8"></div>
            </div>

            <div class="hero-content">
                <h1>Alexander Ludwig</h1>
            </div>

            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </div>

        <section class="countdown-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12">
                        <div class="countdown-content">
                            <h2>My Next Match</h2>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="countdown-timer">
                            <div id="timer">
                                <div id="days"></div>
                                <div id="hours"></div>
                                <div id="minutes"></div>
                                <div id="seconds"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="shop" class="shop-area bg-0f1d22">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-6 image">
                        <div class="shop-image bg1">
                            <img src="assets/img/shop/shop-bg1.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 content">
                        <div class="shop-content">
                            <div class="inner">
                                <h3>Alexander's Footwear</h3>
                                <p>High interest intensity interval training</p>
                                
                                <div class="price">
                                    $39.99 <span>/Per Footwear</span>
                                </div>
                                <a href="#" class="default-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="shop-area bg-0f1d22">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-6 content">
                        <div class="training-content left-content">
                            <div class="inner">
                                <h3>Alexander's Underwear</h3>
                                <p>High interest intensity interval training</p>
                                
                                <div class="price">
                                    $59.99 <span>/Per Underwear</span>
                                </div>
                                <a href="#" class="default-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 image">
                        <div class="shop-image bg2">
                            <img src="assets/img/shop/shop-bg2.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="shop-area bg-0f1d22">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-6 image">
                        <div class="shop-image bg3">
                            <img src="assets/img/shop/shop-bg3.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 content">
                        <div class="shop-content">
                            <div class="inner">
                                <h3>Alexander's Fragrances</h3>
                                <p>High interest intensity interval training</p>
                                
                                <div class="price">
                                    $99.99 <span>/Per Fragrances</span>
                                </div>
                                <a href="#" class="default-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="shop-area bg-0f1d22">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-6 content">
                        <div class="training-content left-content">
                            <div class="inner">
                                <h3>Alexander's Denim</h3>
                                <p>High interest intensity interval training</p>
                                
                                <div class="price">
                                    $119.99 <span>/Per Denim</span>
                                </div>
                                <a href="#" class="default-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 image">
                        <div class="shop-image bg4">
                            <img src="assets/img/shop/shop-bg4.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div id="brand" class="my-clients-area ptb-120 bg-0f1d22">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Brands</span>
                    <h2>I work with brands I believe in</h2>
                </div>

                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img1.png" alt="image">
                            </a>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img2.png" alt="image">
                            </a>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img3.png" alt="image">
                            </a>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img4.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img5.png" alt="image">
                            </a>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img6.png" alt="image">
                            </a>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img7.png" alt="image">
                            </a>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img8.png" alt="image">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="funfacts-area-two pt-120">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="funfacts-box">
                            <i class="flaticon-calendar"></i>
                            <p>Total Matches</p>
                            <h3 class="odometer" data-count="499">00</h3>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="funfacts-box">
                            <i class="flaticon-soccer"></i>
                            <p>Total Goals</p>
                            <h3 class="odometer" data-count="5421">00</h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="funfacts-box">
                            <i class="flaticon-help"></i>
                            <p>Total Assists</p>
                            <h3 class="odometer" data-count="4579">00</h3>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="funfacts-box">
                            <i class="flaticon-football-club"></i>
                            <p>Total Club</p>
                            <h3 class="odometer" data-count="52">00</h3>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="funfacts-box">
                            <i class="flaticon-quality"></i>
                            <p>Total Title</p>
                            <h3 class="odometer" data-count="52">00</h3>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="funfacts-box">
                            <i class="flaticon-trophy"></i>
                            <p>Total Awards</p>
                            <h3 class="odometer" data-count="499">00</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </section>

        <section id="blog" class="blog-area pt-120 pb-90">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Blog</span>
                    <h2>Get to Know Latest Blog</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img10.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <ul class="post-tag">
                                    <li><a href="#">Assist</a></li>
                                    <li><a href="#">Goal</a></li>
                                </ul>

                                <h3><a routerLink="/blog-details">Alexander provides assist in win over FCB</a></h3>

                                <div class="post-meta">
                                    <ul>
                                        <li>By <a href="#">Alexander</a></li>
                                        <li>21 February, 2023</li>
                                    </ul>
                                    <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img11.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <ul class="post-tag">
                                    <li><a href="#">Record</a></li>
                                    <li><a href="#">Win</a></li>
                                </ul>

                                <h3><a routerLink="/blog-details">Alexander Ludwig's stats after latest matchday</a></h3>

                                <div class="post-meta">
                                    <ul>
                                        <li>By <a href="#">Alexander</a></li>
                                        <li>20 February, 2023</li>
                                    </ul>
                                    <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img12.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <ul class="post-tag">
                                    <li><a href="#">Football</a></li>
                                    <li><a href="#">Class</a></li>
                                </ul>

                                <h3><a routerLink="/blog-details">The beginner's guide to Football classes</a></h3>

                                <div class="post-meta">
                                    <ul>
                                        <li>By <a href="#">Alexander</a></li>
                                        <li>19 February, 2023</li>
                                    </ul>
                                    <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="instagram" class="my-instagram-area pt-120 bg-0f1d22">
            <div class="container">
                <div class="instagram-title">
                    <a href="#" target="_blank"><i class="ri-instagram-line"></i> Follow us on @placid</a>
                </div>
            </div>
            <div class="instagram-slides owl-carousel owl-theme">
                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img1.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>

                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img2.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>

                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img3.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img4.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img5.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img6.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img7.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img8.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img9.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
                
                <div class="single-instagram-post">
                    <img src="assets/img/player-instagram/img10.jpg" alt="image">
                    <i class="ri-instagram-line"></i>
                    <a href="#" target="_blank" class="link-btn"></a>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/home-twenty-eight">
                            <img src="assets/img/logo.png" alt="image">
                        </a>
                    </div>

                    <ul class="social">
                        <li><a href="#" target="_blank">Facebook</a></li>
                        <li><a href="#" target="_blank">Twitter</a></li>
                        <li><a href="#" target="_blank">Linkedin</a></li>
                        <li><a href="#" target="_blank">Dribbble</a></li>
                        <li><a href="#" target="_blank">Instagram</a></li>
                    </ul>
                </div>
            </div>

            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Placid is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
                </div>

                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->