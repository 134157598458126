<nav class="navbar navbar-style-two navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/home-eleven">
            <img src="assets/img/small-logo.png" alt="image">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
                <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
            </ul>
            <div class="others-option">
                <a href="#contact" class="default-btn">Get Started</a>
            </div>
        </div>
    </div>
</nav>

<div class="home-slides-two owl-carousel owl-theme">
    <div class="main-banner banner-bg10">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="home-banner-content">
                        <h1 data-shadow='We Connect You to Your Consumers'>We Connect You to Your Consumers</h1>
                        <p>An Agency is a service company hired to plan, create, and handle marketing or advertising related activities on behalf of its clients.</p>
                        <a href="#contact" class="default-btn">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="scroll-btn">
            <a href="#about">
                <span class="mouse">
                    <span></span>
                </span>
            </a>
        </div>
    </div>

    <div class="main-banner banner-bg11">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="home-banner-content">
                        <h1 data-shadow='#1 Ranked Web Marketing Agency'>#1 Ranked Web Marketing Agency</h1>
                        <p>An Agency is a service company hired to plan, create, and handle marketing or advertising related activities on behalf of its clients.</p>
                        <a href="#contact" class="default-btn">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="scroll-btn">
            <a href="#about">
                <span class="mouse">
                    <span></span>
                </span>
            </a>
        </div>
    </div>

    <div class="main-banner banner-bg12">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="home-banner-content">
                        <h1 data-shadow='Your Content Marketing, Solved!'>Your Content Marketing, Solved!</h1>
                        <p>An Agency is a service company hired to plan, create, and handle marketing or advertising related activities on behalf of its clients.</p>
                        <a href="#contact" class="default-btn">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="scroll-btn">
            <a href="#about">
                <span class="mouse">
                    <span></span>
                </span>
            </a>
        </div>
    </div>
</div>

<section id="about" class="about-area ptb-120">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">About Us</span>
            <h2>Leading the way in Creative Digital Marketing</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="about-content">
                    <p>We are a versatile thinking online marketing agency willing to provide exceptional and long lasting solutions for your ever changing and upgrading business. Our main goal is to bring you a proper solution by designing and comparing your business and forming strategy to build a successful path for your business.</p>

                    <div class="features-text">
                        <h4><i class="ri-check-line"></i> Core Development</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="ri-check-line"></i> Define Your Choices</h4>
                        <p>An Agency is a service company hired to plan, create, and handle marketing or advertising related activities on behalf of its clients.</p>
                    </div>

                    <a href="#contact" class="default-btn">Contact Us</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section id="services" class="services-area pt-120 pb-90 bg-0f1d22">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Services</span>
            <h2>We Offer Awesome Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-result"></i>
                    </div>
                    <h3>Business Research</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3>Email Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-content"></i>
                    </div>
                    <h3>Informative Content</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-education"></i>
                    </div>
                    <h3>Innovative Strategies</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Brand Identity</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-team"></i>
                    </div>
                    <h3>Reputation Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <a routerLink="/services-details" class="read-more-btn">Read More<i class="ri-arrow-right-s-line"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area pt-120 pb-90 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class="ri-briefcase-line"></i>
                    <p>Projects Completed</p>
                    <h3 class="odometer" data-count="5421">00</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class="ri-group-2-line"></i>
                    <p>Happy Clients</p>
                    <h3 class="odometer" data-count="4579">00</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class="ri-cup-line"></i>
                    <p>Cup of Coffee</p>
                    <h3 class="odometer" data-count="499">00</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class="ri-trophy-line"></i>
                    <p>My Awards</p>
                    <h3 class="odometer" data-count="52">00</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section id="portfolio" class="works-area pt-120 pb-90">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Works</span>
            <h2>Some of Our Creative Projects</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="single-works-box">
                    <div class="works-image">
                        <img src="assets/img/works/img1.jpg" alt="image">
                        <div class="overlay"></div>
                        <a routerLink="/works-details" class="link-btn"></a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Festival Banner</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Banner</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-8 col-sm-12">
                <div class="single-works-box">
                    <div class="works-image">
                        <img src="assets/img/works/img2.jpg" alt="image">
                        <div class="overlay"></div>
                        <a routerLink="/works-details" class="link-btn"></a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Book Cover Presentation</a></h3>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Book</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-works-box">
                    <div class="works-image">
                        <img src="assets/img/works/img3.jpg" alt="image">
                        <div class="overlay"></div>
                        <a routerLink="/works-details" class="link-btn"></a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Poster Vector</a></h3>
                        <ul>
                            <li><a href="#">HTML</a></li>
                            <li><a href="#">CSS</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-works-box">
                    <div class="works-image">
                        <img src="assets/img/works/img4.jpg" alt="image">
                        <div class="overlay"></div>
                        <a routerLink="/works-details" class="link-btn"></a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Content Writing</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">Text</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-works-box">
                    <div class="works-image">
                        <img src="assets/img/works/img5.jpg" alt="image">
                        <div class="overlay"></div>
                        <a routerLink="/works-details" class="link-btn"></a>
                    </div>

                    <div class="works-content">
                        <h3><a routerLink="/works-details">Digital Marketing</a></h3>
                        <ul>
                            <li><a href="#">Web</a></li>
                            <li><a href="#">HTML</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="feedback-content jarallax" data-jarallax='{"speed": 0.3}'>
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="section-title">
                                <span class="sub-title">Testimonials</span>
                                <h2>What my clients say about us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="feedback-slides-content lr-padding bg-0f1d22">
                    <div class="feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback-item">
                            <i class="ri-double-quotes-r"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>Jason Statham</h3>
                                <span>Web Developer</span>
                            </div>
                        </div>

                        <div class="single-feedback-item">
                            <i class="ri-double-quotes-r"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>Sarah Taylor</h3>
                                <span>Web Designer</span>
                            </div>
                        </div>

                        <div class="single-feedback-item">
                            <i class="ri-double-quotes-r"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>David Warner</h3>
                                <span>PHP Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-area ptb-120">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Team</span>
            <h2>Amazing Team</h2>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-box">
                <img src="assets/img/team/img1.jpg" alt="image">

                <div class="team-content">
                    <h3>Amber Gibs</h3>
                    <span>Developer</span>
                </div>
            </div>

            <div class="single-team-box">
                <img src="assets/img/team/img2.jpg" alt="image">

                <div class="team-content">
                    <h3>Carl Roland</h3>
                    <span>Designer</span>
                </div>
            </div>

            <div class="single-team-box">
                <img src="assets/img/team/img3.jpg" alt="image">

                <div class="team-content">
                    <h3>Paul Wilson</h3>
                    <span>UI Designer</span>
                </div>
            </div>

            <div class="single-team-box">
                <img src="assets/img/team/img4.jpg" alt="image">

                <div class="team-content">
                    <h3>Alice Jenkins</h3>
                    <span>QA Engineer</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="hire-me-area bg-0f1d22 ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="hire-me-content">
                    <span class="sub-title">Have You Any Project?</span>
                    <h2>Build Your Awesome Websites</h2>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="hire-me-btn">
                    <a href="#contact" class="default-btn">Start a Project</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="blog" class="blog-area pt-120 pb-90">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Blog</span>
            <h2>Get to Know Latest News</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-tag">
                            <li><a href="#">Wordpress</a></li>
                            <li><a href="#">SEO</a></li>
                            <li><a href="#">Marketing</a></li>
                        </ul>

                        <h3><a routerLink="/blog-details">How to improve your agency in 2023</a></h3>

                        <div class="post-meta">
                            <ul>
                                <li>By <a href="#">John</a></li>
                                <li>21 February, 2023</li>
                            </ul>
                            <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-tag">
                            <li><a href="#">HTML</a></li>
                            <li><a href="#">CSS</a></li>
                        </ul>

                        <h3><a routerLink="/blog-details">10 features your CMS absolutely needs</a></h3>

                        <div class="post-meta">
                            <ul>
                                <li>By <a href="#">John</a></li>
                                <li>20 February, 2023</li>
                            </ul>
                            <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-tag">
                            <li><a href="#">JS</a></li>
                            <li><a href="#">PHP</a></li>
                        </ul>

                        <h3><a routerLink="/blog-details">The beginner's guide to CSS classes</a></h3>

                        <div class="post-meta">
                            <ul>
                                <li>By <a href="#">John</a></li>
                                <li>19 February, 2023</li>
                            </ul>
                            <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="contact" class="contact-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-content jarallax" data-jarallax='{"speed": 0.3}'>
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="section-title">
                                <span class="sub-title">Let's talk</span>
                                <h2>Get in Touch With Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form lr-padding bg-0f1d22">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Enter your name">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Enter your email">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Enter your message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="footer-area">
    <div class="container">
        <div class="footer-content">
            <div class="logo">
                <a routerLink="/home-eleven">
                    <img src="assets/img/logo.png" alt="image">
                </a>
            </div>

            <div class="footer-contact-info">
                <ul>
                    <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">27 Division St, New York, NY 10002, USA</a></li>
                    <li><i class="ri-phone-line"></i> <a href="tel:+321984754">(+321) 984 754</a></li>
                    <li><i class="ri-mail-line"></i> <a href="mailto:hello@placid.com">hello@placid.com</a></li>
                </ul>
            </div>

            <ul class="social">
                <li><a href="#" target="_blank">Facebook</a></li>
                <li><a href="#" target="_blank">Twitter</a></li>
                <li><a href="#" target="_blank">Linkedin</a></li>
                <li><a href="#" target="_blank">Dribbble</a></li>
                <li><a href="#" target="_blank">Instagram</a></li>
            </ul>
        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <p>© Placid is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div>

        <div class="line"></div>
    </div>
</footer>