<!-- Start Main Container Area -->
<div class="main-container main-container-sticky">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/home-twenty-seven">
                <img src="assets/img/logo.png" alt="image">
            </a>
        </div>

        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                    <li class="nav-item"><a class="nav-link" href="#training">Training</a></li>
                    <li class="nav-item"><a class="nav-link" href="#feedback">Feedback</a></li>
                    <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>
                    <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                </ul>
            </div>
        </div>

        <div class="aside-footer">
            <a href="#contact" class="default-btn">Hire Me</a>

            <ul class="social">
                <li><a href="#" target="_blank"><i class="ri-facebook-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-twitter-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-linkedin-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-dribbble-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
            </ul>
        </div>

        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">

        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="home-area">
            <div class="home-slides owl-carousel owl-theme">
                <div class="main-banner banner-bg3"></div>
                <div class="main-banner banner-bg4"></div>
                <div class="main-banner banner-bg5"></div>
            </div>

            <div class="banner-content">
                <span class="sub-title">Hello I'am</span>
                <h1>Alexander Ludwig</h1>
                <h6>I'm a <span>Gym Trainer</span></h6>
            </div>

            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </div>

        <section id="about" class="about-area ptb-120">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">About Me</span>
                    <h2>Leading the way to a pumped up and refreshing life</h2>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="about-content">
                            <p>Hey there, want to live a healthy and refreshing life? I am a professional gym trainer here to guide you. Bring your body to the perfect shape you desire with our nutrition plan specifically designed keeping your personal need in mind. Our target is to make it a habit to build your life fit and healthy.</p>

                            <div class="tab about-list-tab">
                                <ul class="tabs">
                                    <li>Skills</li>
                                    <li>Awards</li>
                                    <li>Experience</li>
                                    <li>Education</li>
                                    <li>Certification</li>
                                </ul>
            
                                <div class="tab-content">
                                    <div class="tabs-item">
                                        <div class="skills-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Flexible</h3>
                    
                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text" data-speed="2000" data-stop="85">0</span>%</div>
                                                            </div>
                                                        </div>
                    
                                                        <div class="skills-bar">
                                                            <div class="bar-inner"><div class="bar progress-line" data-width="85"></div></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Organized</h3>
                    
                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text" data-speed="2000" data-stop="90">0</span>%</div>
                                                            </div>
                                                        </div>
                    
                                                        <div class="skills-bar">
                                                            <div class="bar-inner"><div class="bar progress-line" data-width="90"></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Stamina</h3>
                    
                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text" data-speed="2000" data-stop="87">0</span>%</div>
                                                            </div>
                                                        </div>
                    
                                                        <div class="skills-bar">
                                                            <div class="bar-inner"><div class="bar progress-line" data-width="87"></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Teaching</h3>
                    
                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text" data-speed="2000" data-stop="92">0</span>%</div>
                                                            </div>
                                                        </div>
                    
                                                        <div class="skills-bar">
                                                            <div class="bar-inner"><div class="bar progress-line" data-width="92"></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Strong Leadership</h3>
                    
                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text" data-speed="2000" data-stop="77">0</span>%</div>
                                                            </div>
                                                        </div>
                    
                                                        <div class="skills-bar">
                                                            <div class="bar-inner"><div class="bar progress-line" data-width="77"></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Motivational</h3>
                    
                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text" data-speed="2000" data-stop="75">0</span>%</div>
                                                            </div>
                                                        </div>
                    
                                                        <div class="skills-bar">
                                                            <div class="bar-inner"><div class="bar progress-line" data-width="75"></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Helpful</h3>
                    
                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text" data-speed="2000" data-stop="80">0</span>%</div>
                                                            </div>
                                                        </div>
                    
                                                        <div class="skills-bar">
                                                            <div class="bar-inner"><div class="bar progress-line" data-width="80"></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Friendly</h3>
                    
                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text" data-speed="2000" data-stop="85">0</span>%</div>
                                                            </div>
                                                        </div>
                    
                                                        <div class="skills-bar">
                                                            <div class="bar-inner"><div class="bar progress-line" data-width="85"></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="tabs-item">
                                        <div class="awards-area">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-awards-box">
                                                        <img src="assets/img/awards/img1.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-awards-box">
                                                        <img src="assets/img/awards/img2.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-awards-box">
                                                        <img src="assets/img/awards/img3.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-awards-box">
                                                        <img src="assets/img/awards/img4.png" alt="image">
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-awards-box">
                                                        <img src="assets/img/awards/img5.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-awards-box">
                                                        <img src="assets/img/awards/img6.png" alt="image">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="tabs-item">
                                        <div class="experience-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>2010 - 2012 (Gym Trainer)</span>
                                                        <h3>Primary Group, Inc.</h3>
                                                        <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>2012 - 2015 (Gym Trainer)</span>
                                                        <h3>EnvyTheme Co.</h3>
                                                        <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>2015 - 2018 (Gym Trainer)</span>
                                                        <h3>Envato Co.</h3>
                                                        <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>2018 - 2023 (Gym Trainer)</span>
                                                        <h3>Themeforest Co.</h3>
                                                        <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="tabs-item">
                                        <div class="education-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>2010 - 2012 (Bachelor's Degree)</span>
                                                        <h3>Stanford University</h3>
                                                        <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>2010 - 2012 (Bachelor's Degree)</span>
                                                        <h3>Harvard University</h3>
                                                        <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>2010 - 2012 (Bachelor's Degree)</span>
                                                        <h3>Columbia University</h3>
                                                        <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>2010 - 2012 (Bachelor's Degree)</span>
                                                        <h3>Stanford University</h3>
                                                        <p>Lorem ipsum dolor sit amet, elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="tabs-item">
                                        <div class="certification-area">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img1.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img2.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img3.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img4.png" alt="image">
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img5.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img6.png" alt="image">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="about-image">
                            <img src="assets/img/about-img3.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="training" class="training-area bg-0f1d22">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-6 image">
                        <div class="training-image bg1">
                            <img src="assets/img/training-bg1.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 content">
                        <div class="training-content">
                            <div class="inner">
                                <h3>Hint</h3>
                                <p>High interest intensity interval training</p>
                                <ul>
                                    <li>120 Minute Sessions</li>
                                    <li>In-Home</li>
                                    <li>Get 1-to-1 training</li>
                                </ul>
                                <div class="price">
                                    $39.99 <span>/Per Session</span>
                                </div>
                                <a href="#" class="default-btn">Book Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="training-area bg-0f1d22">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-6 content">
                        <div class="training-content left-content">
                            <div class="inner">
                                <h3>Boot Camp</h3>
                                <p>Burn a thousand calories per session</p>
                                <ul>
                                    <li>60 Minute Sessions</li>
                                    <li>Private Gym</li>
                                    <li>Get 1-to-1 training</li>
                                </ul>
                                <div class="price">
                                    $69.99 <span>/Per Session</span>
                                </div>
                                <a href="#" class="default-btn">Book Now</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 image">
                        <div class="training-image bg2">
                            <img src="assets/img/training-bg2.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="training-area bg-0f1d22">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-6 image">
                        <div class="training-image bg3">
                            <img src="assets/img/training-bg3.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 content">
                        <div class="training-content">
                            <div class="inner">
                                <h3>Weight Training</h3>
                                <p>The real workout starts when you lift</p>
                                <ul>
                                    <li>120 Minute Sessions</li>
                                    <li>In-Home</li>
                                    <li>Get 1-to-1 training</li>
                                </ul>
                                <div class="price">
                                    $39.99 <span>/Per Session</span>
                                </div>
                                <a href="#" class="default-btn">Book Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="training-area bg-0f1d22">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-6 content">
                        <div class="training-content left-content">
                            <div class="inner">
                                <h3>Personal Training</h3>
                                <p>Guaranties best result</p>
                                <ul>
                                    <li>60 Minute Sessions</li>
                                    <li>Private Gym</li>
                                    <li>Get 1-to-1 training</li>
                                </ul>
                                <div class="price">
                                    $69.99 <span>/Per Session</span>
                                </div>
                                <a href="#" class="default-btn">Book Now</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 image">
                        <div class="training-image bg4">
                            <img src="assets/img/training-bg4.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="book-trainer-area ptb-120">
            <div class="container">
                <div class="book-trainer-content">
                    <h2>Let's do this!</h2>
                    <a href="#contact" class="default-btn">Book a trainer now!</a>
                </div>
            </div>
        </section>

        <section id="feedback" class="feedback-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="feedback-content">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title">
                                        <span class="sub-title">Testimonials</span>
                                        <h2>What my clients say about me</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="feedback-slides-content bg-0f1d22">
                            <div class="feedback-slides owl-carousel owl-theme">
                                <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>Jason Statham</h3>
                                        <span>Web Developer</span>
                                    </div>
                                </div>

                                <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>Sarah Taylor</h3>
                                        <span>Web Designer</span>
                                    </div>
                                </div>

                                <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>David Warner</h3>
                                        <span>PHP Developer</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="blog" class="blog-area pt-120 pb-90">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Blog</span>
                    <h2>Get to Know Latest Blog</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img7.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <ul class="post-tag">
                                    <li><a href="#">Gym</a></li>
                                    <li><a href="#">Fit</a></li>
                                </ul>

                                <h3><a routerLink="/blog-details">How to keep fit over the summer holidays</a></h3>

                                <div class="post-meta">
                                    <ul>
                                        <li>By <a href="#">John</a></li>
                                        <li>21 February, 2023</li>
                                    </ul>
                                    <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img8.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <ul class="post-tag">
                                    <li><a href="#">Record</a></li>
                                    <li><a href="#">Win</a></li>
                                </ul>

                                <h3><a routerLink="/blog-details">Friend of Placid gym breaks deadlift world record</a></h3>

                                <div class="post-meta">
                                    <ul>
                                        <li>By <a href="#">John</a></li>
                                        <li>20 February, 2023</li>
                                    </ul>
                                    <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img9.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <ul class="post-tag">
                                    <li><a href="#">Gym</a></li>
                                    <li><a href="#">Class</a></li>
                                </ul>

                                <h3><a routerLink="/blog-details">The beginner's guide to gym classes</a></h3>

                                <div class="post-meta">
                                    <ul>
                                        <li>By <a href="#">John</a></li>
                                        <li>19 February, 2023</li>
                                    </ul>
                                    <a routerLink="/blog-details" class="details-btn"><i class="ri-arrow-right-s-line"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="contact" class="contact-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="contact-content">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title">
                                        <span class="sub-title">Let's talk</span>
                                        <h2>Get in Touch With Me</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="contact-form bg-0f1d22">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="text" name="name" id="name" class="form-control" placeholder="Enter your name">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Enter your email">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Enter your message"></textarea>
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/home-twenty-seven">
                            <img src="assets/img/logo.png" alt="image">
                        </a>
                    </div>

                    <div class="footer-contact-info">
                        <ul>
                            <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">27 Division St, New York, NY 10002, USA</a></li>
                            <li><i class="ri-phone-line"></i> <a href="tel:+321984754">(+321) 984 754</a></li>
                            <li><i class="ri-mail-line"></i> <a href="mailto:hello@placid.com">hello@placid.com</a></li>
                        </ul>
                    </div>

                    <ul class="social">
                        <li><a href="#" target="_blank">Facebook</a></li>
                        <li><a href="#" target="_blank">Twitter</a></li>
                        <li><a href="#" target="_blank">Linkedin</a></li>
                        <li><a href="#" target="_blank">Dribbble</a></li>
                        <li><a href="#" target="_blank">Instagram</a></li>
                    </ul>
                </div>
            </div>

            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Placid is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
                </div>

                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->