import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root',
})
export class CorreosService {

    baseUrl = environment.basePathUrl;

    constructor(private http: HttpClient) {}



    /**
     * enviarCorreo
     */
    
    enviarCorreo(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/contacto`, data);
    }

   

}