<!-- Start Main Container Area -->
<div class="main-container">
    <aside id="placid-aside" class="placid-aside bg-0f1d22menu">
<!--         <div>
            <a routerLink="/blog-details" class="default-btn">Login </a>

        </div> -->

        <div class="logo" >
            <a routerLink="/">
                <img src="assets/img/logoNegro.png" alt="image" width="300">
            </a>
        </div>
        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">Sobre nosotros</a></li>
                    <li class="nav-item"><a class="nav-link" href="#services">Financiamiento</a></li>
                    <li class="nav-item"><a class="nav-link" href="#aviso">Aviso de privacidad</a></li>
                    <li class="nav-item"><a class="default-btn" href="#contact">Contacto</a></li>
                </ul>
            </div>
        </div>
        <div class="aside-footer">
           <!--  <a href="#contact" class="default-btn">Contáctanos </a> -->
            <ul class="social" style="color: #fff;">Redes
                <li><a href="https://www.facebook.com/EnsignaFinance?locale=es_LA" target="_blank"><i class="ri-facebook-box-line"></i></a></li>
                <li><a href="https://mx.linkedin.com/company/ensignamexico" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                <li><a href="https://www.tiktok.com/@ensignamexico" target="_blank"><i class="ri-tiktok-line"></i></a></li>
                <li><a href="https://www.instagram.com/ensignamexico" target="_blank"><i class="ri-instagram-line"></i></a></li>
            </ul>
        </div>
        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">
        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="main-banner banner-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <span class="sub-title">Bienvenido a</span>
                            <h1>Ensigna</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="scroll-btn">
                <a href="#about">
                    <span class="mouse">
                        <span></span>
                    </span>
                </a>
            </div>
        </div>

        <section id="about" class="about-area ptb-120">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Sobre nosotros
                    </span>
                    <h2>Patrimonio para tu familia.
                    </h2>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="about-content">
                            <p>Nuestro producto y servicio le abre las puertas a un mercado que hoy en día la banca
                                tradicional se las cierra.
                            </p>
                            <br>
                            <p>Nuestra experiencia de muchos años nos permite entender de raíz las áreas de oportunidad
                                que existen actualmente del mercado del financiamiento hipotecario
                            </p>
                            <br>
                            <p>Entorno nace para potencializar el patrimonio de Nosotros las familias mexicanas,
                                dándoles la oportunidad de obtener un financiamiento flexible para poder tener una
                                vivienda propia.</p>
                            <div class="tab about-list-tab">
                                <ul class="tabs">
                                    <li>Ventajas</li>
                                </ul>

                                <div class="tab-content">
                                    <div class="tabs-item">

                                        <div class="experience-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <!-- <span>2010 - 2012 (Web Developer)</span> -->
                                                        <h3>Buró de Crédito y Enganche Flexibles</h3>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <h3>Plazos de hasta 15 años</h3>

                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <h3>Flexibilidad en la comprobación de ingresos</h3>

                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <h3>Contamos con autorización de la Secretaría de Economía para
                                                            operar</h3>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <h3>Nuestro contrato está adherido a Profeco</h3>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="about-image">
                            <img src="assets/img/patrimonio.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <hr style="border-color: #C061B9; border-width: 2px; border-style: dashed;">

        <section id="services" class="services-area pt-120 pb-90 bg-0f1d22blanco">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Financiamiento</span>
                    <h2>¡Estrena tu nueva casa!</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon" style="color: white !important;">
                                <i class="flaticon-calendar"></i>
                            </div>
                            <h3>Paso 1</h3>
                            <p>Agenda una cita en sucursal para llenar una solicitud de financiamiento y obten respuesta
                                en sólo 24 horas
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon" style="color: white !important;">
                                <i class="flaticon-help"></i>
                            </div>
                            <h3>Paso 2</h3>
                            <p>Recibe una asesoría de nuestros ejecutivos certificados para conocer los planes que más
                                te convengan según tu enganche y plazo final.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon" style="color: white !important;">
                                <i class="flaticon-quality"></i>
                            </div>
                            <h3>Paso 3</h3>
                            <p>Firma tu contrato, paga tu cuota de inscripción y comienza a pagar tus mensualidades.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon" style="color: white !important;">
                                <i class="flaticon-analysis"></i>
                            </div>
                            <h3>Paso 4</h3>
                            <p>Adjudícate con cualquiera de nuestros distíntos métodos.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon" style="color: white !important;">
                                <i class="flaticon-trophy"></i>
                            </div>
                            <h3>Paso 5 </h3>
                            <p>¡Estrena tu nueva casa!
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        </section>

        <!-- <section id="portfolio" class="works-area pt-120 pb-90">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Works</span>
                    <h2>Some of My Creative Projects</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img1.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Festival Banner</a></h3>
                                <ul>
                                    <li><a href="#">Web</a></li>
                                    <li><a href="#">Banner</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img2.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Book Cover Presentation</a></h3>
                                <ul>
                                    <li><a href="#">Design</a></li>
                                    <li><a href="#">Book</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img3.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Poster Vector</a></h3>
                                <ul>
                                    <li><a href="#">HTML</a></li>
                                    <li><a href="#">CSS</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img4.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Content Writing</a></h3>
                                <ul>
                                    <li><a href="#">Web</a></li>
                                    <li><a href="#">Text</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img5.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/works-details" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/works-details">Digital Marketing</a></h3>
                                <ul>
                                    <li><a href="#">Web</a></li>
                                    <li><a href="#">HTML</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="feedback-area" id="aviso">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="feedback-content jarallax" data-jarallax='{"speed": 0.3}'>
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title" hidden>
                                        <h2>Aviso de privacidad</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="feedback-slides-content bg-0f1d22">
                            <div class="feedback-slides owl-carousel owl-theme">
                                <div class="single-feedback-item">
                                    <h2>Aviso de privacidad</h2>
                                    <i class="ri-double-quotes-r"></i>
                                    
                                    <p>Ensiacceso Inmobiliario SAPI de CV, en lo sucesivo ENSIGNA
                                        presenta este Aviso de Privacidad el cual está apegado a lo
                                        dispuesto en la Ley Federal de Protección de Datos Personales
                                        en Posesión de Particulares, en lo sucesivo LEY, así como su
                                        Reglamento y los Lineamientos del Aviso de Privacidad, en lo
                                        sucesivo LEGISLACIÓN y aplica a los datos e información
                                        personal de los clientes o clientes potenciales, en lo sucesivo
                                        TITULAR, recopilada or ENSIGNA con la finalidad de poder
                                        prestar los servicios y productos.
                                        ENSIGNA es una sociedad constituida de conformidad con las
                                        leyes de los Estados Unidos Mexicanos y con domicilio en Av.
                                        Colinas del Cimatario 429-10. Col. Colinas del Cimatario.
                                        Querétaro, Qro. CP 76090 y pone a diposición la siguiente
                                        cuenta de correo electrónico dudas@ensigna.mx para
                                        cualquier duda con respecto a este Aviso de Privacidad.
                                        ENSIGNA recabará, del TITULAR, datos de identificación y
                                        contacto, datos fiscales, datos patrimoniales y fiscales con el
                                        objetivo de prestar los servicios y productos.
                                        Los datos proporcionados por el TITULAR, así como los que
                                        sean generados mientras exista la relación comercial podrán
                                        ser utilizados de manera enunciativa, mas no limitativa, para
                                        lo siguiente:
                                    </p>
                                    <br>
                                    <p>1. La correcta operación y prestación de los servicios
                                        incluyendo seguros.</p>
                                    <p>2. Informar sobre cambios relacionados con el producto o servicio.
                                    </p>
                                    <p>3. Cumplir con las obligaciones adquirirdas con los clientes.
                                    </p>
                                    <p>4. Mejoras y mediciones de calidad de nuestros servicios.
                                    </p>
                                    <p>5. Emitir comunicados referentes al producto o servicio,
                                        promociones, beneficios, bonificaciones y publicidad en
                                        general.</p>
                                    <p>6. Gestión de cobranza.
                                    </p>
                                    <p>7. Comunicar nuevos productos y servicios, así como
                                        actualizaciones.</p>
                                    <p>8. Consulta de historial crediticio.
                                    </p>
                                    <p>9. Proporcionar la información que, con base en el
                                        Reglamento de los Sistemas de Comercialización, sea
                                        requerida.</p>
                                    <p>10. Prospección y mercadotecnia.
                                    </p>

                                    <p>El TITULAR autoriza a ENSIGNA a que sus datos sean
                                        transferidos a empresas nacionales o extranjeras, filiales,
                                        controladoras o subsidiarias de Ensiacceso Inmobiliario SAPI
                                        de CV, así como con aquellas empresas con las que se tengan
                                        relaciones comerciales en el entendido que dichos terceros
                                        tienen las mismas obligaciones y responsabilidades que
                                        ENSIGNA para proteger los datos personales. El TITULAR podrá
                                        optar porque sus datos no sean transferidos a través de
                                        dudas@ensigna.mx</p>

                                    <p>El TITULAR tiene el derecho de acceder a los datos personales
                                        que ENSIGNA posea, así como a actualizarlos, corregirlos en
                                        caso de ser inexactos o bien solicitar a ENSIGNA que sean
                                        cancelados si considera que son innecesarios o irrelevantes.
                                        Para ejercer su derecho ARCO, el TITULAR puede enviar un
                                        escrito al domicilio de ENSIGNA el cual deberá contener: datos
                                        de identificación junto con una identificación en original y
                                        copia, manifestando los derechos ARCO que desea ejercer y
                                        deberá estar firmado por el TITULAR. Una vez que ENSIGNA
                                        firme de recibido se contará con un plazo no mayor a 20 días
                                        hábiles contados a partir de la fecha de recibido para ejecutar
                                        dichos derechos y será comunicada la confirmación al
                                        domicilio proporcionado por el TITULAR en un plazo no mayor
                                        a 15 días hábiles posteriores a la fecha de ejecución.
                                        El TITULAR podrá revocar en todo momento su consentimiento
                                        para el tratamiento de sus datos personales y ENSIGNA lo
                                        aceptará siempre y cuando no se trate de datos que tengan
                                        que ser resguardados y/o almacenados para obligaciones
                                        legales. Así mismo se informa que ante algunos casos de
                                        solicitud de revocación es posible que ENSIGNA suspenda la
                                        relación comercial.</p>

                                    <p>ENSIGNA podrá utilizar los datos del TITULAR para fines
                                        mercadológicos durante el tiempo que sea necesario
                                        conforme a las disposiciones legales. En caso de no estar de
                                        acuerdo puede contactar a dudas@ensigna.mx para
                                        manifestar su negativa para seguir recibiendo promociones o
                                        beneficios sobre nuestros productos o servicios.
                                        Cualquier cambio al Aviso de Privacidad será publicado en
                                        ensigna.mx y cuando el TITULAR proporciona sus datos, por
                                        cualquier medio, acepta y reconoce este Aviso de Privacidad
                                        por lo que le otorga a ENSIGNA la facultad para proceder con
                                        el tratamiento estipulado en este Aviso de Privacidad y con
                                        estricto apego a la LEGISLACIÓN, sin perjuicio de los derechos
                                        que le confiere la LEGISLACIÓN. Ante cualquier duda o
                                        inquietud el TITULAR puede contactar a dudas@ensigna.mx.</p>


                                </div>

                                <!-- <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt
                                        ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>Sarah Taylor</h3>
                                        <span>Web Designer</span>
                                    </div>
                                </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <div class="my-clients-area ptb-120">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Clients</span>
                    <h2>My Honoranle Clients</h2>
                </div>

                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img1.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img2.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img3.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img4.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img5.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img6.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img7.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img8.png" alt="image">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <section class="hire-me-area bg-0f1d22 ptb-120">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12">
                        <div class="hire-me-content">
                            <span class="sub-title">Have You Any Project?</span>
                            <h2>I am Available for Freelance Hire</h2>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="hire-me-btn">
                            <a href="#contact" class="default-btn">Contáctanos</a>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->


        <hr style="border-color: #000000; border-width: 2px; border-style: dashed;">

        <section id="contact" class="contact-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="contact-content jarallax" data-jarallax='{"speed": 0.3}'>
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title">
                                        <span class="sub-title">Contacto</span>
                                        <h2>Envianos un mensaje</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="contact-form bg-0f1d22">
                            <form [formGroup]="contactForm" (ngSubmit)="submitForm()">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="text" formControlName="name" class="form-control" placeholder="Ingresa tu nombre">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="email" formControlName="email" class="form-control" placeholder="Ingresa tu email">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="tel" formControlName="celular" class="form-control" placeholder="Ingresa tu celular">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="number" formControlName="monto" class="form-control" placeholder="Ingresa monto deseado">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" formControlName="privacyCheckbox">
                                            <label class="form-check-label" for="privacyCheckbox">
                                                Leí y acepto el Aviso de Privacidad
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Enviar mensaje</button>
                                    </div>
                                    <!-- Mensaje de error -->
                                    <div *ngIf="formError" class="col-12">
                                        <div class="alert alert-danger" role="alert">
                                            Por favor, completa todos los campos correctamente antes de enviar el formulario.
                                        </div>
                                    </div>
                                </div>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/ensignaLogo.png" alt="image" width="300">
                        </a>
                    </div>
                    <div class="footer-contact-info">
                        <ul>
                            <li></li>
                            <li><i class="ri-phone-line"></i> <a href="tel:4424540299">442 454 0299</a></li>
                            <li><i class="ri-mail-line"></i> <a href="mailto:dudas@ensigna.mx">dudas@ensigna.mx</a></li>
                        </ul>
                    </div>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/EnsignaFinance?locale=es_LA" target="_blank">Facebook</a></li>
                        <li><a href="https://mx.linkedin.com/company/ensignamexico" target="_blank">Linkedin</a></li>
                        <li><a href="https://www.tiktok.com/@ensignamexico" target="_blank">Tiktok</a></li>
                        <li><a href="https://www.instagram.com/ensignamexico" target="_blank">Instagram</a></li>
                    </ul>
                </div>
            </div>
            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Ensigna <a href="" target="_blank">finance</a></p>
                </div>
                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->