import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CorreosService } from '../../services/correos.service';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  contactForm: FormGroup;
  formError: boolean = false; 

  constructor(private fb: FormBuilder,
    private correosService: CorreosService
  ) { }

  ngOnInit() {

    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      celular: ['', Validators.required],
      monto: ['', Validators.required],
      privacyCheckbox: [false, Validators.requiredTrue]
  });
  }

  submitForm(): void {
    // Handle form submission here

    if (this.contactForm.valid) {
      let envio = {
        asunto: 'Solicitud de prestamo',
        nombre: this.contactForm.value.name,
        correoContacto: this.contactForm.value.email,
        cuerpo : `Mi nombre es ${this.contactForm.value.name} y estoy interesado en solicitar un prestamo de ${this.contactForm.value.monto} mi numero de contacto es: ${this.contactForm.value.celular}`
      }
  
      this.correosService.enviarCorreo(envio).subscribe((res) => {
        console.log(res);
      }
      );
      // console.log('Formulario enviado', this.contactForm.value);
      this.formError = false;  // Reiniciar el estado del error si el formulario es válido
    } else {
      this.formError = true;  // Mostrar el mensaje de error si el formulario no es válido
    }


  }

}
