<div hidden>
    <button class="sidebar-demo-control">
        <span>Demos</span>
    </button>
</div>

<div class="example-demo-modal">
    <div class="inner">
        <ul>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-1.jpg" alt="image">
                    <span>Digital Marketer</span><a href="/" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-2.jpg" alt="image">
                    <span>Photographer</span><a href="/home-two" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-3.jpg" alt="image">
                    <span>Gym Trainer</span><a href="/home-three" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-4.jpg" alt="image">
                    <span>Footballer</span><a href="/home-four" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-5.jpg" alt="image">
                    <span>Digital Agency</span><a href="/home-five" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-6.jpg" alt="image">
                    <span>Backpacker</span><a href="/home-six" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-7.jpg" alt="image">
                    <span>Digital Marketer</span><a href="/home-seven" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-8.jpg" alt="image">
                    <span>Photographer</span><a href="/home-eight" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-9.jpg" alt="image">
                    <span>Gym Trainer</span><a href="/home-nine" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-10.jpg" alt="image">
                    <span>Footballer</span><a href="/home-ten" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-11.jpg" alt="image">
                    <span>Digital Marketer</span><a href="/home-eleven" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-12.jpg" alt="image">
                    <span>Photographer</span><a href="/home-twelve" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-13.jpg" alt="image">
                    <span>Gym Trainer</span><a href="/home-thirteen" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-14.jpg" alt="image">
                    <span>Footballer</span><a href="/home-fourteen" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-15.jpg" alt="image">
                    <span>Digital Agency</span><a href="/home-fifteen" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-16.jpg" alt="image">
                    <span>Backpacker</span><a href="/home-sixteen" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-17.jpg" alt="image">
                    <span>Digital Marketer</span><a href="/home-seventeen" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-18.jpg" alt="image">
                    <span>Photographer</span><a href="/home-eighteen" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-19.jpg" alt="image">
                    <span>Gym Trainer</span><a href="/home-nineteen" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-20.jpg" alt="image">
                    <span>Footballer</span><a href="/home-twenty" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-21.jpg" alt="image">
                    <span>Digital Marketer</span><a href="/home-twenty-one" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-22.jpg" alt="image">
                    <span>Photographer</span><a href="/home-twenty-two" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-23.jpg" alt="image">
                    <span>Gym Trainer</span><a href="/home-twenty-three" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-24.jpg" alt="image">
                    <span>Footballer</span><a href="/home-twenty-four" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-25.jpg" alt="image">
                    <span>Digital Agency</span><a href="/home-twenty-five" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-26.jpg" alt="image">
                    <span>Backpacker</span><a href="/home-twenty-six" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-27.jpg" alt="image">
                    <span>Digital Marketer</span><a href="/home-twenty-seven" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-28.jpg" alt="image">
                    <span>Photographer</span><a href="/home-twenty-eight" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-29.jpg" alt="image">
                    <span>Gym Trainer</span><a href="/home-twenty-nine" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-30.jpg" alt="image">
                    <span>Footballer</span><a href="/home-thirty" class="link-btn"></a>
                </div>
            </li>

            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-31.jpg" alt="image">
                    <span>Digital Marketer</span><a href="/home-thirty-one" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-32.jpg" alt="image">
                    <span>Photographer</span><a href="/home-thirty-two" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-33.jpg" alt="image">
                    <span>Gym Trainer</span><a href="/home-thirty-three" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-34.jpg" alt="image">
                    <span>Footballer</span><a href="/home-thirty-four" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-35.jpg" alt="image">
                    <span>Digital Agency</span><a href="/home-thirty-five" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-36.jpg" alt="image">
                    <span>Backpacker</span><a href="/home-thirty-six" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-37.jpg" alt="image">
                    <span>Digital Marketer</span><a href="/home-thirty-seven" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-38.jpg" alt="image">
                    <span>Ripple Animation</span><a href="/home-thirty-eight" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-39.jpg" alt="image">
                    <span>Particles Animation</span><a href="/home-thirty-nine" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-40.jpg" alt="image">
                    <span>Background Video</span><a href="/home-forty" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/services-details.jpg" alt="image">
                    <span>Services Details</span><a href="/services-details" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/works-details.jpg" alt="image">
                    <span>Works Details</span><a href="/works-details" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/blog-details.jpg" alt="image">
                    <span>Blog Details</span><a href="/blog-details" class="link-btn"></a>
                </div>
            </li>
        </ul>
    </div>
    <div class="header-title">
        <button class="example-demo-modal-control">
            <i class="ri-close-line"></i>
        </button>
        <div class="title">View Demo</div>
    </div>
</div>