<!-- Start Main Container Area -->

<section class="blog-details-area">

    <div class="container">
            <div class="col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <div class="container">
                            <div class="row justify-content-center">
                                <img src="assets/img/ensignaLogo.png" alt="Imagen"
                                    style="width: 300px !important; margin-bottom: 90px;" />
                                <div class="col-md-10 mx-auto">
                                    <div class="card text-white"
                                        style="margin: 0 auto; width: 300px; background-color: #303030;">
                                        <div class="card-body text-center">

                                            <form id="loginForm" method="POST">

                                                <div class="col-lg-12 col-md-3" style="margin-bottom: 20px;">
                                                    <p>Ingresa tus datos</p>
                                                </div>


                                                <div class="col-lg-12 col-md-3">
                                                    <div class="form-group mb-3">
                                                        <input type="cliente" name="cliente" id="cliente"
                                                            class="form-control" placeholder="Número de Cliente">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-3">
                                                    <div class="form-group mb-3">
                                                        <input type="password" name="password" id="password"
                                                            class="form-control" placeholder="Contraseña">
                                                    </div>
                                                </div>

                                                <div class="mb-3">
                                                    <div class="text-center">
                                                        <a href="/blog-details" id="registerLink"
                                                            class="btn btn-link custom-text"
                                                            data-action="register">Registrarme</a>
                                                        <a href="/blog-details" id="forgotPasswordLink"
                                                            class="btn btn-link custom-text"
                                                            data-action="forgotPassword">Olvidé mi contraseña</a>
                                                    </div>
                                                </div>

                                                <button type="submit" class="default-btn">Iniciar
                                                    Sesión</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
</section>



<footer class="footer-area">
    <div class="container">
        <div class="footer-content">

            <div class="footer-contact-info">
                <ul>
                    <li></li>
                    <li><i class="ri-phone-line"></i> <a href="tel:4424540299">442 454 0299</a></li>
                    <li><i class="ri-mail-line"></i> <a href="mailto:dudas@ensigna.mx">dudas@ensigna.mx</a></li>
                </ul>
            </div>
            <ul class="social">
                <li><a href="https://www.facebook.com/EnsignaFinance?locale=es_LA" target="_blank">Facebook</a></li>
                <li><a href="https://mx.linkedin.com/company/ensignamexico" target="_blank">Linkedin</a></li>
                <li><a href="https://www.tiktok.com/@ensignamexico" target="_blank">Tiktok</a></li>
                <li><a href="https://www.instagram.com/ensignamexico" target="_blank">Instagram</a></li>
            </ul>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <p>© Ensigna <a href="" target="_blank">finance</a></p>
        </div>
        <div class="line"></div>
    </div>
</footer>
<!-- End Main Container Area -->