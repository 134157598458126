import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-thirty-one',
  templateUrl: './home-thirty-one.component.html',
  styleUrls: ['./home-thirty-one.component.scss']
})
export class HomeThirtyOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
