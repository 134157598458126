<!-- Start Main Container Area -->
<div class="main-container">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/">
                <img src="assets/img/logo.png" alt="image">
            </a>
        </div>

        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                    <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                    <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
                    <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>
                    <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                </ul>
            </div>
        </div>

        <div class="aside-footer">
            <a href="#contact" class="default-btn">Hire Me</a>

            <ul class="social">
                <li><a href="#" target="_blank"><i class="ri-facebook-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-twitter-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-linkedin-box-line"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-dribbble-fill"></i></a></li>
                <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
            </ul>
        </div>

        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">

        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <section class="services-details-area bg-0f1d22 ptb-120">
            <div class="container">
                <div class="services-details-overview">
                    <div class="services-details-desc">
                        <h3>Business Research</h3>
                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                        <div class="features-text">
                            <h4><i class="ri-check-line"></i> Core Development</h4>
                            <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                        </div>

                        <div class="features-text">
                            <h4><i class="ri-check-line"></i> Define Your Choices</h4>
                            <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                        </div>
                    </div>

                    <div class="services-details-image">
                        <img src="assets/img/blog/img9.jpg" alt="image">
                    </div>
                </div>

                <div class="services-details-overview">
                    <div class="services-details-image">
                        <img src="assets/img/blog/img1.jpg" alt="image">
                    </div>

                    <div class="services-details-desc">
                        <h3>Email Marketing</h3>
                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>

                        <div class="features-text">
                            <h4><i class="ri-check-line"></i> Core Development</h4>
                            <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                        </div>

                        <div class="features-text">
                            <h4><i class="ri-check-line"></i> Define Your Choices</h4>
                            <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="image">
                        </a>
                    </div>

                    <div class="footer-contact-info">
                        <ul>
                            <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">27 Division St, New York, NY 10002, USA</a></li>
                            <li><i class="ri-phone-line"></i> <a href="tel:+321984754">(+321) 984 754</a></li>
                            <li><i class="ri-mail-line"></i> <a href="mailto:hello@placid.com">hello@placid.com</a></li>
                        </ul>
                    </div>

                    <ul class="social">
                        <li><a href="#" target="_blank">Facebook</a></li>
                        <li><a href="#" target="_blank">Twitter</a></li>
                        <li><a href="#" target="_blank">Linkedin</a></li>
                        <li><a href="#" target="_blank">Dribbble</a></li>
                        <li><a href="#" target="_blank">Instagram</a></li>
                    </ul>
                </div>
            </div>

            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Placid is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
                </div>

                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->